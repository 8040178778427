import $ from "jquery";

const removeTableRow = () => {
  $("tbody").on("click", ".remove-table-row", function (event) {
    $(this).closest("tr").remove();
    return event.preventDefault();
  });
};

export { removeTableRow };
